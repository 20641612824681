body, html {
  height: 100%;
  margin: 0;
  padding-top: 26px;
}

.grey-image {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.6;
  filter: alpha(opacity=60);
  }

.homepage-content{
  padding-top: 20px;
  text-align: center;
}

.discount-box-homepage{
  padding:20px;
  padding-top: 0px;
  background-color: #FFE8F2;
}

.text-discount-box-homepage{
  font-weight: 400;
  margin-top: 20px;
  margin-bottom:0px;
}

select {
  background-color: white;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  outline: none;
  color: black;
}

option{
  color: black;
}

.card-container{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  overflow: hidden;
}

.discount-box-outline-homepage{
  outline: dashed #494949;
  background-color: white;
  padding: 5px 10px 5px 10px;
  margin-top: 20px;
}

.homepage-icons-text{
  color: #494949;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 20px;
}
.homepage-icons-subtext{
  color: #7a7a7a;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 12px;
}

.image-product-item{
  width: 100%;
  max-width: 200px;
  aspect-ratio: 1 / 1;  /* defining the aspect ratio of the image */
  object-fit: cover; 
}

.image-product-item{
  transition: transform .5s ease;
}

.image-product-item:hover{
  transform: scale(1.1);
}

.product-item-image{
  border-radius: 50%;
  width: 120px;  
  height: 120px;
  border: 3px solid #f5f5f5; 
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.product-item-image:hover {
  transform: scale(1.1); /* Ingrandisce l'immagine del 10% al passaggio del mouse */
}

.image-holder{

  max-width: 200px;
}

.discount-label{
  padding: 2px 5px 2px 5px;
  background-color: #FFA965;
  border-radius: 2px;
  color:white;
  font-weight: bold;
}

.new-label{
  padding: 2px 5px 2px 5px;
  background-color: #FF554E;
  border-radius: 2px;
  color:white;
  font-weight: bold;
}

.outOfStock-label{
  padding: 2px 5px 2px 5px;
  background-color: #664F9E !important;
  border-radius: 2px;
  color:white;
  font-weight: bold;
}

.image-holder-label{
  display: inline-block;
  margin-right: 5px;
}

.discount-text-card{
  font-size: 13px;
  color: #696969;
  text-decoration: line-through;
}

.label-container-image{
  position: absolute;
  left: 10px;
  top: 10px;
}

.image-product-page{
  width: 100%;
  height: auto;
}

.custom-button-home{
  max-width:  200px !important;
  background:    #ffe8f2;
  border-radius: 6px;
  padding:       15px 50px;
  color:         #494949;
  display:       inline-block;
  font:          normal bold 15px/1 "Open Sans", sans-serif;
  text-align:    center;
  border: none;
}

.custom-button{
  background:    #333333;
  border-radius: 2px;
  padding:       15px 50px;
  color:         #e0e0e0;
  display:       inline-block;
  font:          normal bold 15px/1 "Open Sans", sans-serif;
  text-align:    center;
  border: none;
}

.custom-button-search{
  max-width: 300px !important;
  width: 100%;
  background:    #ffe8f2;
  border-radius: 2px;
  padding:       16px 20px;
  color:         #494949;
  display:       inline-block;
  font:          normal bold 15px/1 "Open Sans", sans-serif;
  text-align:    center;
  border: none;
}

.product-item-text{
  color: #494949;
  font-weight: bold;
  position: relative;
  cursor: pointer;
}

.product-page-title{
  color: #494949;
  font-weight: bold;
}

.product-page-price{
  color: #a8a8a8;
  font-weight: bold;
}

.product-page-description-title{
  color: #494949;
  font-style: italic;
}

.product-page-description{
  color: #a8a8a8;
  text-align: justify;
  font-weight: 400;
}

.product-item-text::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px; /* Regola questa distanza a seconda dell'altezza del testo */
  width: 100%;
  height: 2px; /* Spessore della sottolineatura */
  background-color: currentColor; /* Colore della sottolineatura */
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out; /* Regola la durata dell'animazione */
}

.product-item-text:hover::after {
  transform: scaleX(1);
}

.hompage-product-container-item{
  padding: 20px;
}

.homepage-product-item{
  /*background-color: #f5f5f5;*/
  padding: 15px 5px 5px 5px;
  border-radius: 2%;
}

.navbar{
  box-shadow: 0px 5px 5px -6px rgba(0,0,0,.5);
  }

.footer-container{
  background-color: #FFE8F2;
  text-align: center;
  padding-top: 20px;
}

.footer-icons{
  display: flex;
  justify-content: center;
}

.cart-logo-link{
  color: #000;
}

.footer-icon{
  margin: 0px 20px 0px 0px;
}

.footer-header{
}

.footer-text{
  font-style: italic;
}

.footer-about-us-text{
  padding: 10px 20px 0px 20px;
  text-align: justify;
  font-size: 15px;
  color: #494949 ;
}

.footer-about-us-title{
  font-style: italic;
  font-size: 18px;
  color: #494949 ;
}

.footer-contacts-title{
  font-style: italic;
  font-size: 18px;
  color: #494949 ;
}

.footer-header-item{
  padding-top: 20px;
  padding-bottom: 20px;
}

.title-homepage-image{
  color:white;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}

.subtitle-homepage-image{
  color:white;
  font-size: 20px;
  font-weight: 100;
  text-align: center;
}

.homepage-image{
  height: 500px;
  overflow: hidden;
  background-image: url("https://rellery.com/cdn/shop/collections/RL.BOVAL.XL.5.jpg?v=1652835983");
  background-size: cover;
  background-position: center;
  display: flex;
}

.search-image{
  height: 260px;
  overflow: hidden;
  background-image: url("https://cdn.leonardo.ai/users/63ed3d04-8f74-48df-bf0d-2d7eda5d324d/generations/a77d82e9-be53-4267-916c-0ee9f17ae273/AlbedoBase_XL_tanti_gioielli_lussuosi_in_sfondo_bianco_0.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
}

.button-homepage-image{
  cursor: pointer; 
  border: 1px solid white; 
  background-color: transparent !important; 
  height: 50px; 
  width: 200px !important; 
  color: white; 
  font-size: 1.5em; 
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6); 
  font-size: 20px;
  margin:0 auto;
  display:block;
}

.search-bar-container{
  background-color: white;
  border-radius: 2px;
}

.search-bar-container-background{
  padding: 20px 15px 20px 15px;
  background-color: #2727276e;
}

.search-bar-column{
  border-right:1px solid rgb(209, 209, 209);
}

.search-bar-container2{
  border-radius: 5px;
  padding: 12px 20px 12px 20px;
  box-shadow: -1px -1px 20px 5px rgba(186,186,186,0.75);
  -webkit-box-shadow: -1px -1px 20px 5px rgba(186,186,186,0.75);
  -moz-box-shadow: -1px -1px 20px 5px rgba(186,186,186,0.75);
  margin-bottom: 50px;
  max-width: 900px !important;
}

.serach-bar-container-wrapper{

}

.order-box-info{
  background-color: #E6F5FA;
  border: 1px solid #0096C7;
  padding: 20px 20px 10px 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.search-bar-input{
  border:0px;
  outline:0;
  width: 100%;
  background-color: white;
}

.search-bar-input-underline{
  height: 1px;
  background-color: #dbdbdb;
}

.serach-bar-text{
  font-size: 15px;
  color: #9b9b9b ;
}

.logo-name{
  font-family:Arial, Helvetica, sans-serif;
  letter-spacing: 3px;
  margin: auto;
  height: 50%;
  text-align: center;
}

.logo{
  height: 50px;
  width: auto;
  margin-top: -13px;
  margin-right: 5px;
  margin-bottom: -10px;
}

.logo-writing{
  height: 25px;
  width: auto;
  margin-top: -13px;
  margin-right: 5px;
  margin-bottom: -10px;
}

.navbar-item {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  left: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}